import React from "react";
import { Seo, PageHero, menuItems } from "../components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import tw, { styled } from "twin.macro";

const HomeWrapper = styled.div`
  ${tw`my-4 w-full md:mx-auto max-w-[1440px] overflow-x-hidden`}

  .cards {
    ${tw`w-full flex flex-col gap-10 mt-6 mb-10`}
  }

  .additional-links {
    ${tw`w-full bg-[#d9e6f2] dark:bg-white/20 flex flex-col items-center px-4 md:px-8 my-8 py-4 md:py-8 shadow-one max-w-[1300px] xl:rounded-2xl lg:mx-auto`}
  }

  .additional-links > h3 {
    ${tw`mx-auto text-center`}
  }

  .additional-links > p {
    ${tw`mx-auto text-center mt-6 mb-12 md:mt-10 md:mb-16 text-base`}
  }

  div.links {
    ${tw`w-full flex flex-row flex-nowrap justify-around`}
  }

  .link-block {
    ${tw`block w-[74px] h-[74px] md:w-[104px] md:h-[104px] lg:w-[160px] lg:h-[160px] bg-secondary text-nav relative rounded-2xl shadow-three transition duration-300 ease-in-out fill-current dark:fill-['white']`}
  }

  .link-block:hover {
    transform: translateY(-8px);
  }

  .icon {
    ${tw`w-[24px] h-[24px] top-[20px] left-[25px] md:(w-[40px] h-[40px] left-[32px] top-[23px]) lg:(w-[64px] h-[64px] left-[48px] top-[40px]) text-nav dark:text-white absolute`}
  }

  .link-block > p {
    ${tw`absolute left-0 right-0 bottom-[7px] md:(bottom-[12px] text-base) text-center text-xs dark:text-white`}
  }
`;

const CardWrapper = styled.section`
  ${tw`w-[443px] sm:w-[600px] md:w-[750px] lg:w-[900px] flex flex-row flex-nowrap bg-secondary justify-between items-center p-4 rounded-2xl`}
  box-shadow: 16px 16px 32px 16px var(--gray-one);

  .image-div {
    ${tw`my-2 max-h-[208px] overflow-hidden transition duration-1000 ease-in-out rounded-2xl `}
  }

  .image-div:hover {
    transform: scale(0.9);
  }

  .image-div img {
    ${tw`w-full h-full my-auto float-left transition duration-1000 ease-in-out`}
  }
  .image-div img:hover {
    transform: scale(1.2);
  }

  .content-div {
    ${tw`w-full`}
  }

  .content-div > a {
    ${tw`block w-full h-6 mt-2 text-white bg-active rounded text-sm sm:(text-base w-[150px] ml-0 mt-8) text-center shadow-xl hover:shadow-none transition duration-1000 ease-in-out`}
  }
  .content-div > a:hover {
    transform: scale(1.1);
  }

  .content-div > h3 {
    ${tw`text-base md:text-2xl`}
  }

  .content-div > p {
    ${tw`text-sm md:text-base`}
  }

  p.footnote {
    ${tw`text-xs`}
  }

  .footnote > span {
    ${tw`text-red-700 text-sm`}
  }

  &:nth-child(odd) {
    ${tw`self-start`}
    transform: translateX(-60px);

    @media (min-width: 1536px) {
      transform: translateX(20%);
    }
  }

  &:nth-child(odd) .content-div {
    ${tw`pl-2 sm:pl-4`}
  }

  &:nth-child(even) {
    ${tw`self-end flex-row-reverse`}
    transform: translateX(60px);
    @media (min-width: 1536px) {
      transform: translateX(-20%);
    }
  }

  &:nth-child(even) .content-div {
    ${tw`pr-2 sm:pr-4`}
  }
`;

const Card = ({ title, body, footnote, url, urlText, children }) => {
  return (
    <CardWrapper>
      {children}
      <div className="content-div">
        <h3>{title}</h3>
        <p>{body}</p>
        {footnote ? (
          <p className="footnote">
            <span>&#42;</span>
            {footnote}
          </p>
        ) : null}
        <Link to={url}>{urlText}</Link>
      </div>
    </CardWrapper>
  );
};

const HomePage = () => {
  const links = menuItems.filter(
    (item) => item.name !== "Message" && item.name !== "Event"
  );
  return (
    <>
      <Seo
        title="Home"
        description="University Bible Fellowship (UBF) at Ryerson University Home page"
      />
      <PageHero
        img={
          <StaticImage
            src={"../images/scott-webb-NmIP_VQfL-Q-unsplash.jpg"}
            alt="Bread in a basket on a table with dimmed background"
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
      >
        <>
          <h1>
            Young Disciple Christian Fellowship{" "}
            <span>University Bible Fellowship&nbsp;(UBF) at Ryerson</span>
          </h1>

          <p>
            For God so loved the world that he gave his one and only Son, that
            whoever believes in him shall not perish but have eternal life.
            (John 3:16)
          </p>
          <div className="button-group">
            <Link id="service-btn" to="/about">
              Explore&nbsp;Activities
            </Link>
            <Link id="message-btn" to="/message">
              Explore&nbsp;Messages
            </Link>
          </div>
        </>
      </PageHero>

      <HomeWrapper>
        <section className="cards">
          <Card
            title="Fellowship Activities"
            body="Our activities include Sunday service, group bible study, and one-to-one bible study to assist you in your spiritual journey."
            footnote="Activities are now online due to the COVID-19 pandemic. We will return to in-person (in addition to online) in the near future. You are welcome to join us."
            url="/about"
            urlText="Go to Activities"
          >
            <div className="image-div">
              <StaticImage
                src="../images/dylan-ferreira-HJmxky8Fvmo-unsplash.jpg"
                alt="Meaningful online video chatting in a friendly environment"
                placeholder="blurred"
                layout="constrained"
                width={660}
              />
            </div>
          </Card>

          <Card
            title="Weekly Messages"
            body="Feel free to listen or read through our weekly messages which are uploaded every Sunday."
            url="/message"
            urlText="Go to Messages"
          >
            <div className="image-div">
              <StaticImage
                src="../images/joel-muniz-XqXJJhK-c08-unsplash.jpg"
                alt="A girl is reading a book in a quiet place."
                placeholder="blurred"
                layout="constrained"
                width={660}
              />
            </div>
          </Card>

          <Card
            title="Events"
            body="Want to get more involved in the community? You are welcome to view our upcoming and past events."
            url="/event"
            urlText="View Events"
          >
            <div className="image-div">
              <StaticImage
                src="../images/erika-giraud-7KhbREQPFD4-unsplash.jpg"
                alt="Two men are hugging for greeting."
                placeholder="blurred"
                layout="constrained"
                width={660}
              />
            </div>
          </Card>
        </section>
        <section className="additional-links">
          <h3>Additional Sources</h3>
          <p>
            Learn more by exploring about, blogs, daily bread or contact us for
            any inquiries.
          </p>
          <div className="links">
            {links.map(({ name, path, icon }) => {
              const Icon = icon;

              return (
                <Link
                  className="link-block"
                  to={path === "/about" ? "/about/#about-section" : path}
                  key={name}
                >
                  <Icon className="icon" />
                  <p>{name}</p>
                </Link>
              );
            })}
          </div>
        </section>
      </HomeWrapper>
    </>
  );
};

export default HomePage;
